import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery, useLoginMutation } from './store/api';

function Login(props: { userId: string | null }) {
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginMutation, loginMutationResult] = useLoginMutation();
  const { data, isError, isLoading } = useGetCurrentUserQuery('current');

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      email: { value: string };
      password: { value: string };
    };
    loginMutation({
      email: formElements.email.value,
      password: formElements.password.value,
    });
  };

  useEffect(() => {
    if (loginMutationResult.isError) {
      setLoginFailed(true);
    }
  }, [loginMutationResult]);

  useEffect(() => {
    if (loginMutationResult.isSuccess) {
      navigate('/dashboard')
    }
  }, [navigate, loginMutationResult]);
  
  return (
    <>
      <main>
        <section
          className="container"
          style={{ marginTop: '4rem', marginBottom: '1rem' }}
        >
          <div className="row">
            <form
              style={{
                width: '100%',
                maxWidth: '330px',
                padding: '1rem',
                margin: 'auto',
              }}
              onSubmit={handleSubmit}
            >
              <div
                className="form-floating"
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="email"
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div
                className="form-floating"
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>

              <Button className="w-100 btn btn-lg btn-primary" type="submit">
                Log in
              </Button>
              {loginFailed && <p style={{ color: 'red' }}>Login failed</p>}
            </form>
          </div>
        </section>
      </main>
    </>
  );
}

export default Login;

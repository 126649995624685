import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  useConsumeSavesMutation,
  useCreateSongMutation,
  useGetCurrentSongQuery,
  useGetSavesForSongQuery,
  useDeleteSongMutation,
} from './store/api';
import axios from 'axios';
import { isAfter } from 'date-fns';
import { Modal } from 'react-bootstrap';

function CopiedNotification(props: { show: boolean }) {
  return props.show ? (
    <span className="color-muted">&nbsp;Copied</span>
  ) : (
    <FontAwesomeIcon icon={faCopy} />
  );
}

function Dashboard(props: { userId: string | null }) {
  const [createSongMutation, createSongMutationResult] =
    useCreateSongMutation();

const [deleteSongMutation, deleteSongMutationResult] = useDeleteSongMutation()

  const [consumeSavesMutation, consumeSavesMutationResult] =
    useConsumeSavesMutation();

  const [show, setShow] = useState(false);
  const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
  const [displaySong, setDisplaySong] = useState(false);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied === true) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleShow = () => setShow(true);

  const handleSave = () => {
    consumeSavesMutation(songData?.id ?? '');
  };

  const handleCreateSong = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      artistId: { value: string };
      isrc: { value: string };
      releaseDate: { value: string };
    };
    createSongMutation({
      artistId: formElements.artistId.value,
      isrc: formElements.isrc.value,
      releaseDate: formElements.releaseDate.value,
    });
  };

  const songQueryResult = useGetCurrentSongQuery('current');
  const songData = songQueryResult.data;
  const savesQueryResult = useGetSavesForSongQuery(songData?.id ?? '');
  const savesData = savesQueryResult.data;

  useEffect(() => {
    if (displaySuccessMsg === true) {
      setTimeout(() => {
        setDisplaySuccessMsg(false);
      }, 5000);
    }
  }, [displaySuccessMsg]);

  const [media, setMedia] = useState(null as any);

  useEffect(() => {
    if (songData?.isrc != null || createSongMutationResult.isSuccess) {
      axios.get(`/api/media/${songData?.isrc}`).then((r) => {
        const { data } = r;
        setMedia(data);
      });
      setDisplaySong(true);
    }
  }, [createSongMutationResult, songData]);

  useEffect(() => {
    if (consumeSavesMutationResult.isSuccess) {
      setShow(false);
      setDisplaySong(false);
      setDisplaySuccessMsg(true);
      setCheckboxChecked(false);
      setMedia(null);
    }
  }, [consumeSavesMutationResult]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const handleCheckboxChange = () => setCheckboxChecked(!checkboxChecked);

  const handleDelete = () => {
    deleteSongMutation(songData?.id ?? '');
    setTimeout(() => location.reload(), 1500)
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <main>
        <section
          style={{
            marginTop: '5rem',
            marginBottom: '1rem',
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
        >
          {displaySuccessMsg && (
            <p style={{ color: 'green' }}>
              Successfully processed all pending saves for {songData?.isrc}
            </p>
          )}
          <div className="container"></div>
          <div className="row row-cols-md-12">
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="card shadow-sm">
                <div className="card-body">
                  <img
                    style={{
                      maxWidth: '150px',
                      maxHeight: '100px',
                      minWidth: '70px',
                      padding: '10px',
                      paddingBottom: '20px',
                    }}
                    src="/Spotify_Logo_RGB_Green.png"
                  ></img>
                  {displaySong === true ? (
                    <>
                      {media ? (
                        <div>
                          <img
                            style={{ maxWidth: '300px' }}
                            src={media.album.art[1].url}
                          ></img>
                          <h3
                            style={{
                              paddingBottom: '0',
                              marginBottom: '5px',
                              marginTop: '5px',
                            }}
                          >
                            <a href={media.album.url}>{media.song.name}</a>
                          </h3>
                          <span className="text-muted">
                            <a href={media.artist.url}>{media.artist.name}</a>
                          </span>
                          <p style={{ fontSize: '9pt', marginTop: '.5rem' }}>
                            Artist Id: <b>{songData?.artistId}</b>
                            <br />
                            Track ISRC: <b>{songData?.isrc}</b>
                          </p>
                        </div>
                      ) : (
                        <div>
                          <h3>Song not available yet</h3>
                          <p>
                            More details will be available after your song has
                            been released.
                          </p>
                          <p style={{ fontSize: '9pt', marginTop: '.5rem' }}>
                            Artist Id: <b>{songData?.artistId}</b>
                            <br />
                            Track ISRC: <b>{songData?.isrc}</b>
                            <br />
                            Release date: <b>{songData?.releaseDate}</b>
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <form onSubmit={handleCreateSong}>
                        <div
                          className="form-floating"
                          style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="artistId"
                            placeholder="Spotify Artist Id"
                            name="artistId"
                          />
                          <label htmlFor="floatingInput">
                            Spotify Artist Id
                          </label>
                        </div>

                        <div
                          className="form-floating"
                          style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="isrc"
                            placeholder="Track ISRC"
                            name="isrc"
                          />
                          <label htmlFor="floatingInput">Track ISRC</label>
                        </div>

                        <div
                          className="form-floating"
                          style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        >
                          <input
                            type="date"
                            className="form-control"
                            id="releaseDate"
                            placeholder="Release date"
                            name="releaseDate"
                          />
                          <label htmlFor="floatingInput">Release date</label>
                        </div>
                        <Button variant="primary" type="submit">
                          Create presave link
                        </Button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {displaySong === true && (
                <div
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  className="card shadow-sm"
                >
                  <div className="card-body">
                    <h3>Presave Link</h3>
                    <span style={{ cursor: 'pointer' }}>
                      <CopyToClipboard
                        text={`https://songsave.xyz/api/presave/${props.userId}`}
                        onCopy={() => setCopied(true)}
                      >
                        <span>
                          <span
                            style={{
                              textDecoration: 'underline',
                              color: 'blue',
                              cursor: 'pointer',
                            }}
                          >
                            https://songsave.xyz/api/presave/{props.userId}
                          </span>
                          &nbsp;
                          <CopiedNotification
                            show={copied}
                          ></CopiedNotification>
                        </span>
                      </CopyToClipboard>
                    </span>
                    <div style={{marginTop: '1rem'}}>
                    <Button variant="danger" onClick={() => setShowDeleteModal(true)}>Reset</Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-5">
              <div className="card shadow-sm">
                <div
                  className="card-body"
                  style={{
                    opacity: isAfter(
                      new Date(),
                      new Date(songData?.releaseDate ?? ''),
                    )
                      ? '1'
                      : '.2',
                  }}
                >
                  <h3>Save to user libraries</h3>
                  <Form>
                    <p>Pending saves: {savesData?.saves ?? 0}</p>
                    <p>
                      <i>
                        This will save the album and track to user's libraries
                        and clear your pending saves.
                      </i>
                    </p>
                    <fieldset
                      disabled={
                        isAfter(
                          new Date(),
                          new Date(songData?.releaseDate ?? ''),
                        ) === false
                      }
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="I'm ready"
                          onChange={handleCheckboxChange}
                        />
                      </Form.Group>

                      <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={checkboxChecked !== true || (savesData?.saves ?? 0) < 1}
                      >
                        Run save process
                      </Button>
                    </fieldset>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          <AreYouSure show={showDeleteModal} setShow={setShowDeleteModal} handleConfirmedDelete={handleDelete}></AreYouSure>
        </section>
      </main>
    </>
  );
}

function AreYouSure(props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, handleConfirmedDelete: () => void}) {
  return <Modal show={props.show} onHide={() => props.setShow(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Delete presave link</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Are you sure you want to delete your active presave link? Any pending saves will be lost.</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={() => props.setShow(false)}>No, take me back</Button>
    <Button variant="danger" onClick={() => props.handleConfirmedDelete()}>Yes, delete</Button>
  </Modal.Footer>
</Modal>
}

export default Dashboard;

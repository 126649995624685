import React from 'react';
import Navbar from './Navbar';
import { Outlet, Route, Routes } from 'react-router-dom';
import About from './About';
import Register from './Register';
import Login from './Login';
import Dashboard from './Dashboard';
import { useGetCurrentUserQuery } from './store/api';

function App() {
  const { data, isError, isLoading } = useGetCurrentUserQuery('current');

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Navbar currentUserEmail={data?.email ?? null}></Navbar>
            <Outlet />
          </>
        }
      >
        <Route path="/" element={<About />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login userId={data?.id ?? null} />} />
        <Route
          path="/dashboard"
          element={<Dashboard userId={data?.id ?? null} />}
        />
      </Route>
    </Routes>
  );
}

export default App;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function About() {
  return (
    <main>
      <section
        className="text-center container"
        style={{ marginTop: '4rem', marginBottom: '1rem' }}
      >
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">literally just a presave link</h1>
            <p className="lead text-muted d-none d-lg-block">
              Song presave services for indie artists have become too bloated
              and complex. Songsave gives you what you want: a simple song
              presave link. Nothing more, nothing less.
            </p>
            <p
              className="lead text-muted d-lg-none"
              style={{ marginBottom: '1rem' }}
            >
              Nothing more, nothing less.
            </p>
            <Link to="/register"
              className="btn btn-primary"
              style={{ marginBottom: '.5rem' }}>Create</Link>
          </div>
        </div>
      </section>

      <div className="bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h3 style={{ marginBottom: '1rem' }}>
                    <FontAwesomeIcon color="red" icon={faBan} />
                    &nbsp;greedy permissions?
                  </h3>
                  <p>&gt; &#128515; you just found a cool new indie artist</p>
                  <p>&gt; &#128525; they have a 3rd-party presave link</p>
                  <p>&gt; &#129326; you click the link and see this</p>
                  <img
                    src="/greedypermissions.jpg"
                    style={{
                      width: '100%',
                      marginTop: '.5rem',
                      marginBottom: '.5rem',
                    }}
                  ></img>
                  <p>&gt; &#128517; Why does it need all these permissions?</p>
                  <p>
                    &gt; &#129320; I don't want it to edit my private playlists
                  </p>
                  <p>&gt; &#128529; I just wanted to presave the song</p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h3 style={{ marginBottom: '1rem' }}>
                    <FontAwesomeIcon color="red" icon={faBan} />
                    &nbsp;selling my data?
                  </h3>
                  <p className="text-muted">
                    most 3rd-party presave links are sending customer data to
                    tracking services.
                  </p>
                  <p>
                    We grabbed this screenshot of the network traffic from a
                    popular presave link service:
                  </p>

                  <img
                    src="/trackers.jpg"
                    style={{
                      width: '100%',
                      height: '400px',
                      marginTop: '.5rem',
                      marginBottom: '.5rem',
                      overflow: 'hidden',
                    }}
                  ></img>
                  <p>
                    This comically long list includes Tiktok analytics, Facebook
                    analytics, Google ad tracking, Twitter analytics, and
                    unknown proprietary analytics.
                  </p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h3 style={{ marginBottom: '1rem' }}>
                    <FontAwesomeIcon color="green" icon={faCheck} />
                    &nbsp;just a presave link
                  </h3>

                  <ul>
                    <li>
                      <p>songsave is not greedy with permissions</p>
                    </li>
                    <li>
                      <p>songsave does not sell your listener's data</p>
                    </li>
                    <li>
                      <p>songsave just gives you a presave link</p>
                    </li>
                  </ul>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Permission</th>
                        <th>Why</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Basic profile info</td>
                        <td>Required by Spotify API</td>
                      </tr>
                      <tr>
                        <td>Manage who you follow on Spotify</td>
                        <td>To follow the artist</td>
                      </tr>
                      <tr>
                        <td>Add and remove items in Your Library</td>
                        <td>To save the song</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;

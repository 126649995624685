import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Register() {
  const navigate = useNavigate();
  const [registerFailed, setRegisterFailed] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      email: { value: string };
      password: { value: string };
      spotifyArtistId: { value: string };
    };
    try {
      const response = await axios.post(
        '/api/register',
        {
          email: formElements.email.value,
          password: formElements.password.value,
        },
      );
      if (response.status === 200) {
        navigate('/login');
      } else {
        setRegisterFailed(true);
      }
    } catch (err) {
      console.error(err);
      setRegisterFailed(true);
    }
  };

  return (
    <main>
      <section
        className="container"
        style={{ marginTop: '4rem', marginBottom: '1rem' }}
      >
        <div className="row">
          {/* <form
            style={{
              width: '100%',
              maxWidth: '330px',
              padding: '1rem',
              margin: 'auto',
            }}
            onSubmit={handleSubmit}
          >
            <div
              className="form-floating"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div
              className="form-floating"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="password"
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary" type="submit">
              Register
            </button>
            {registerFailed && <p style={{ color: 'red' }}>Login failed</p>}
          </form> */}
          Songsave is still in development - registration not available.
        </div>
      </section>
    </main>
  );
}

export default Register;

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useLogoutMutation } from './store/api';
const cookies = new Cookies();

function Navbar(props: { currentUserEmail: string | null }) {
  const [logoutMutation, logoutMutationResult] = useLogoutMutation()

  const navigate = useNavigate();

  const logout = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logoutMutation();
  };

  useEffect(() => {
    if (logoutMutationResult.isSuccess) {
      navigate('/login')
    }
  }, [logoutMutationResult])

  return (
    <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
      <div className="container-fluid">
        <Link to={'/'} className="navbar-brand">songsave</Link>
        {props.currentUserEmail && !logoutMutationResult.isSuccess ? (
          <div className="row">
            <div className='col'>
              <span>{props.currentUserEmail}</span>
            </div>
            <div className="col">
              <Link to="/dashboard">Dashboard</Link>
            </div>
            <div className="col">
              <a href="" onClick={(event) => logout(event)}>
                Log out
              </a>
            </div>
          </div>
        ) : (
          <Link to="/login">Log in</Link>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
